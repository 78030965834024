.wrapper{
  #sidebar{
    @media (min-width: 1px) and (max-width: 991.98px) {  
      margin-left: 0px;
    }
    .sidebar-content{
      background-color: #19202E;
      @media (min-width: 1px) and (max-width: 991.98px) {  
        left: 0px;
      }

      .sidebar-brand{
        .siderbar-image-logo{
          margin: 20px 0 0 20px;
        }
      }
      
      .sidebar-header{
        color: #FFF;
        font-size: 18px;
      }

      .sidebar-link{
        background-color: #19202E;
        color: #FFF;
        &:active, &:focus, &:hover{
          background-color: #313b4c;
        }
        svg{
          stroke: #FFF;
          opacity: .8;
        }
      }
      .sidebar-bottom{
        background-color: #19202E;
        color: #FFF;
      }
    }
  }
  
  .main{
    .navbar-top{
      .sidebar-icon{
        cursor: pointer;    
      }
    }
  }
}
