.ant-carousel {
  .slick-dots {
    li {
      button {
        background: rgb(67, 56, 56);
      }
    }
    li.slick-active {
      button {
        background: #000;
      }
    }
  }
}

.order-status-approve {
 background-color: #a180da; 
}
.order-status-active {
    background-color: #5b7dff;
}
.order-status-complete {
    background-color: #5fc27e;
}
.order-status-tobereturned {
    background-color: #fcc100;
}